export default {
  blogerName: 'MikeSonMikeDad',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/mikesonmikedad',
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/mikesonmikedad_twitch/',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c4f413f20',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irit.com/c8028315a',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs12.com/c085fcc76',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs12.com/ca3dc2446',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs10.com/cb8fd2738',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/c32b7ace5',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/cac84be93',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetb-intsemed2.com/c0b72ecd5',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/ca9426c9a',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cc53a2c99',
      gameTitle: 'Doors Of Sol (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>SONHO</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'SONHO',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
